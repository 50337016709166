<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="6" fill="white" />
    <path d="M13.134 4.74975H6.62476V19.2502H10.5165C10.69 19.25 10.8564 19.181 10.979 19.0582C11.1016 18.9354 11.1705 18.769 11.1705 18.5955V15.8115H12.1455C16.9065 15.8115 18.8558 13.131 18.8558 9.9915C18.8483 6.114 15.9053 4.74975 13.134 4.74975Z" fill="url(#paint0_linear_204_1292)" />
    <defs>
      <linearGradient
        id="paint0_linear_204_1292"
        x1="3.98926"
        y1="16.1085"
        x2="16.7453"
        y2="5.40525"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#00A3F1" />
        <stop offset="0.44" stop-color="#0090F8" />
        <stop offset="1" stop-color="#006EFF" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {}
</script>
