<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_344_1370)">
      <path d="M39.975 16.163c.545 3.122-7.956 7.212-18.99 9.133C9.955 27.218.57 26.243.026 23.118c-.544-3.122 7.96-7.21 18.991-9.132 11.033-1.923 20.416-.947 20.96 2.177Z" fill="#034EA2" />
      <path d="m29.592 20.797-.056-3.246h1.023v4.092h-1.472l-1.021-3.358h-.023l.057 3.358h-1.018v-4.092h1.537l.95 3.246h.023ZM10.98 17.934l-.566 3.752H9.297l.767-4.135h1.84l.763 4.135h-1.113l-.55-3.752h-.024ZM15.804 20.712l.51-3.161h1.687l.09 4.135h-1.034l-.027-3.718h-.022l-.691 3.718h-1.05l-.691-3.718h-.023l-.025 3.718h-1.036l.092-4.135h1.687l.51 3.161h.023ZM7.556 20.52c.04.1.028.229.01.306-.035.138-.129.278-.403.278-.26 0-.417-.149-.417-.375v-.4h-1.11v.32c0 .921.726 1.2 1.504 1.2.748 0 1.364-.255 1.461-.945.05-.357.013-.591-.004-.68-.174-.865-1.744-1.124-1.86-1.608a.544.544 0 0 1-.005-.218c.029-.131.12-.277.378-.277.241 0 .384.15.384.376v.255h1.031v-.29c0-.901-.808-1.042-1.394-1.042-.735 0-1.337.243-1.446.916a1.45 1.45 0 0 0 .009.559c.18.844 1.649 1.089 1.862 1.625ZM21.004 20.512c.04.1.027.225.01.303-.035.137-.127.275-.4.275-.256 0-.412-.149-.412-.37v-.397h-1.099l-.001.316c0 .913.719 1.188 1.489 1.188.74 0 1.35-.252 1.447-.935.05-.355.014-.586-.004-.672-.173-.858-1.727-1.114-1.843-1.594a.524.524 0 0 1-.004-.214c.03-.133.118-.274.375-.274.24 0 .38.145.38.37v.253h1.023v-.288c0-.89-.802-1.03-1.383-1.03-.727 0-1.323.24-1.43.908-.03.184-.034.346.01.552.177.836 1.631 1.08 1.842 1.61ZM24.477 21.068c.287 0 .377-.199.396-.3a.865.865 0 0 0 .01-.158v-3.061h1.046v2.966c.003.077-.005.233-.009.272-.074.772-.684 1.022-1.443 1.022-.761 0-1.372-.25-1.445-1.022a3.909 3.909 0 0 1-.009-.271v-2.967h1.045v3.06c0 .054.002.114.01.158.023.102.11.3.4.3ZM33.105 21.024c.3 0 .404-.19.423-.3a.953.953 0 0 0 .01-.156v-.6h-.425v-.604h1.467v1.11c0 .078-.002.135-.015.273-.068.752-.721 1.021-1.454 1.021-.734 0-1.386-.269-1.456-1.021a3.616 3.616 0 0 1-.015-.273v-1.741c0-.074.01-.203.018-.273.092-.773.719-1.022 1.453-1.022.733 0 1.376.248 1.452 1.022.013.132.009.273.009.273v.138h-1.043v-.231c.001 0-.001-.099-.013-.157-.017-.092-.097-.302-.413-.302-.301 0-.389.198-.411.302a.95.95 0 0 0-.018.195v1.891c0 .052.003.11.011.157.017.109.12.298.42.298Z" fill="#fff" />
    </g>
    <defs>
      <clipPath id="clip0_344_1370">
        <path fill="#fff" d="M0 0h40v40H0z" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {}
</script>
