<template>
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="size"
  >
    <path
      d="M19.63 5.55a9.975 9.975 0 0 0-15.26 0c-.005.008-.012.012-.017.02a9.964 9.964 0 0 0 0 12.86c.005.008.012.012.018.02a9.975 9.975 0 0 0 15.258 0c.006-.008.013-.012.018-.02a9.964 9.964 0 0 0 0-12.86c-.005-.008-.012-.012-.018-.02Zm-1.638.482a7.672 7.672 0 0 1-1.806 1.64 11.832 11.832 0 0 0-1.33-3.627 8.484 8.484 0 0 1 3.136 1.987ZM15.077 12c0 .713-.039 1.394-.103 2.046a8.978 8.978 0 0 0-5.948 0A20.705 20.705 0 0 1 8.923 12c0-.712.039-1.394.103-2.045a8.978 8.978 0 0 0 5.948 0c.064.651.103 1.333.103 2.045ZM12 20.462c-1.062 0-2.213-1.94-2.757-4.874a7.692 7.692 0 0 1 5.514 0c-.544 2.934-1.695 4.874-2.757 4.874Zm0-11.539a7.693 7.693 0 0 1-2.757-.51C9.787 5.478 10.938 3.538 12 3.538s2.213 1.94 2.757 4.873c-.88.338-1.814.511-2.757.511ZM9.143 4.045a11.833 11.833 0 0 0-1.33 3.627 7.672 7.672 0 0 1-1.805-1.64 8.484 8.484 0 0 1 3.135-1.987ZM5.005 7.246a9.226 9.226 0 0 0 2.539 2.06 22.542 22.542 0 0 0 0 5.385 9.23 9.23 0 0 0-2.539 2.06 8.436 8.436 0 0 1 0-9.508v.003Zm1 10.722a7.675 7.675 0 0 1 1.806-1.64c.242 1.274.691 2.499 1.33 3.627a8.484 8.484 0 0 1-3.133-1.987h-.003Zm8.846 1.987a11.832 11.832 0 0 0 1.33-3.627c.685.447 1.295 1 1.806 1.64a8.484 8.484 0 0 1-3.13 1.987h-.006Zm4.138-3.201a9.224 9.224 0 0 0-2.538-2.06c.108-.894.163-1.794.164-2.694 0-.9-.054-1.799-.161-2.692a9.225 9.225 0 0 0 2.538-2.06 8.436 8.436 0 0 1 0 9.508l-.003-.002Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#FFFFFF'
    },

    size: {
      type: Number,
      default: 24
    }
  }
}
</script>
