<template>
  <Link class="location" :href="link" target="_blank">
    <LocationIcon class="location-icon" />
    {{ name }}
  </Link>
</template>

<script>
import Link from '@/components/common/Link/Link'
import LocationIcon from '@/components/icons/default/Location.vue'

export default {
  components: {
    Link,
    LocationIcon
  },

  props: {
    name: {
      type: String,
      required: true
    },
    coordinates: {
      type: Array,
      required: true
    }
  },

  computed: {
    link () {
      const zoom = 14
      const coordinates = this.coordinates.join(',')
      return `https://www.google.com/maps/search/${encodeURIComponent(this.name)}/@${coordinates},${zoom}z`
    }
  }
}
</script>

<style lang="scss" scoped>
.location {
  display: flex;
  font-size: 14px;
  position: relative;
  align-items: center;

  &-icon {
    margin-right: 4px;
  }
}
</style>
