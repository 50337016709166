<template>
  <div class="embedded-player-modal">
    <Modal
      scroll="keep"
      :is-active="embeddedPlayerModalIsActive"
      :title="$t('modules.embedded_player.title')"
      @onDismiss="closeEmbeddedPlayerModal"
    >
      <div class="embedded-player-modal__content">
        <EmbeddedPlayerIFrame
          class="embedded-player-modal__body-iframe"
          :title="getIFrameTitle()"
          :src="iframeSrc"
          :episode-id="episodeId || 'latest'"
          :link="getIFrameLink()"
        />

        <fieldset class="embedded-player-modal__body-mode">
          <div v-for="option in modes" :key="option.id">
            <input
              :id="option.id"
              v-model="activeMode"
              class="embedded-player-modal__body-mode-input"
              type="radio"
              name="mode"
              :value="option.id"
            ></input>
            <label
              :for="option.id"
              class="embedded-player-modal__body-mode-label"
            >
              <div class="embedded-player-modal__body-mode-label-head">
                <BRadio
                  type="is-light-purple"
                  :native-value="activeMode !== option.id"
                  @click.native.prevent="activeMode = option.id"
                />
                <h5 class="embedded-player-modal__body-mode-label-head-title">
                  {{ option.title }}
                </h5>
              </div>
              <p class="embedded-player-modal__body-mode-label-body">
                {{ option.description }}
              </p>
            </label>
          </div>
        </fieldset>

        <div class="embedded-player-modal__body-theme">
          <h5 class="embedded-player-modal__body-theme-title">
            {{ $t('modules.embedded_player.theme_title') }}
          </h5>
          <fieldset class="embedded-player-modal__body-theme-field">
            <template v-for="option in themes">
              <input
                :id="option.id"
                :key="option.id"
                v-model="activeTheme"
                class="embedded-player-modal__body-theme-field-input"
                type="radio"
                name="theme"
                :value="option.id"
              >
              <label
                :key="`${option.id}-label`"
                class="embedded-player-modal__body-theme-field-label"
                :for="option.id"
              >
                {{ option.text }}
              </label>
            </template>
          </fieldset>
        </div>

        <BInput
          class="embedded-player-modal__body-code"
          type="textarea"
          maxlength="300"
          disabled="true"
          :value="getCode()"
        />
      </div>

      <template slot="footer">
        <button
          class="embedded-player-modal__footer-btn"
          @click="copyToClipboard"
        >
          <CopyIcon class="embedded-player-modal__footer-btn-icon" />
          <div class="embedded-player-modal__footer-btn-text">
            {{ $t('modules.embedded_player.copy_button') }}
          </div>
        </button>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { config } from '@/config'
import toast from '@/lib/toast'
import { copyToClipboard } from '@/lib/utils'

import Modal from '@/components/common/Modal/Modal'
import EmbeddedPlayerIFrame from '@/components/modals/embedded-player/EmbeddedPlayerIFrame.vue'
import CopyIcon from '@/components/icons/dashboard/Copy.vue'

export default {
  components: {
    Modal,
    CopyIcon,
    EmbeddedPlayerIFrame
  },

  props: {
    podcast: {
      type: Object,
      default: null
    },
    episode: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      activeTheme: 'default-theme',
      activeMode: this.episode ? 'single-mode' : 'latest-mode'
    }
  },

  computed: {
    themes () {
      return [{
        id: 'default-theme',
        value: 'default',
        text: this.$t('single_words.default')
      }, {
        id: 'light-theme',
        value: 'light',
        text: this.$t('single_words.light')
      }, {
        id: 'dark-theme',
        value: 'dark',
        text: this.$t('single_words.dark')
      }]
    },

    modes () {
      const singleMode = {
        id: 'single-mode',
        title: this.$t('modules.embedded_player.modes.single_mode.title'),
        description: this.$t('modules.embedded_player.modes.single_mode.description') + this.episodeTitle
      }
      const latestMode = {
        id: 'latest-mode',
        title: this.$t('modules.embedded_player.modes.latest_mode.title'),
        description: this.$t('modules.embedded_player.modes.latest_mode.description')
      }
      const showMode = {
        id: 'show-mode',
        title: this.$t('modules.embedded_player.modes.show_mode.title'),
        description: this.$t('modules.embedded_player.modes.show_mode.description')
      }

      if (this.episodeId) {
        return [singleMode, showMode]
      } else {
        return [latestMode, showMode]
      }
    },

    embeddedPlayerModalIsActive () {
      return Boolean(this.$route.query.embedded)
    },

    activeThemeName () {
      const theme = this.themes.find((t) => {
        return t.id === this.activeTheme
      })

      if (!theme || theme.id === 'default-theme') {
        return ''
      }

      return theme.value
    },

    iframeSrc () {
      let src = `${config.embeddedPlayerUrl}/${this.$route.params.slug}/`

      const modeParams = this.getModeParams()
      src = src + modeParams

      if (this.activeThemeName) {
        src = src + `?theme=${this.activeThemeName}`
      }

      if (this.$route.query.time) {
        src = src + `?time=${this.$route.query.time}`
      }
      return src
    },

    episodeId () {
      if (isNaN(this.$route.query.episode)) {
        return null
      }

      return parseInt(this.$route.query.episode)
    },

    episodeTitle () {
      return this.episode?.title.replace(/"([^"]*)"/g, '«$1»')
    }
  },

  async mounted () {
    const podcastSlug = this.$route.params.slug
    if (podcastSlug) {
      const episodes = await this.getEpisodes({ slug: this.$route.params.slug, limit: 6 })
      this.episodesLength = episodes.length
    }
  },

  methods: {
    ...mapActions('podcasts', ['getEpisodes']),

    closeEmbeddedPlayerModal () {
      this.$router.push({
        query: {
          embedded: undefined
        }
      })
    },

    copyToClipboard () {
      copyToClipboard(this.getCode())
      toast(this.$t('sentences.copied_to_clipboard'), 'info')
    },

    getModeParams () {
      let modeParams = ''

      switch (this.activeMode) {
        case 'single-mode':
          modeParams = this.episodeId
          break
        case 'latest-mode':
          modeParams = 'latest'
          break
        case 'show-mode':
          modeParams = ''
          break
        default:
          modeParams = ''
          break
      }

      return modeParams
    },

    getIFrameTitle () {
      const episodeTitle = this.episodeTitle
      const podcastTitle = this.podcast.title

      return episodeTitle && this.activeMode === 'single-mode' ? episodeTitle : podcastTitle
    },

    getIFrameLink () {
      const podcastSlug = this.$route.params.slug
      const episodeId = this.episodeId
      let url = `${config.baseUrl}/podcasts/${podcastSlug}/`
      url = episodeId && this.activeMode === 'single-mode' ? url + episodeId : url
      return `<a href="${url}">${this.getIFrameTitle()}</a>`
    },

    getStaticHeight () {
      return this.activeMode === 'single-mode' || this.activeMode === 'latest-mode' ? 154 : 345
    },

    getCode () {
      const title = this.getIFrameTitle()
      const height = this.getStaticHeight()
      const link = this.getIFrameLink()
      return `<iframe src="${this.iframeSrc}" title="${title}" width="100%" height="${height}px" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen scrolling="no">${link}</iframe>`
    }
  }
}
</script>

<style lang="scss" scoped>
.embedded-player-modal {
  &__content {
    display: flex;
    gap: $gap * 1.5;
    position: relative;
    flex-direction: column;
  }

  &__body {
    &-mode {
      display: flex;
      flex-direction: row;
      gap: $gap;

      &-input {
        display: none;
      }

      &-label {
        cursor: pointer;
        width: 100%;
        color: #FFFFFF;
        border: 1px solid #9991A4;
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        padding: $gap;
        gap: $gap * 0.75;

        &-head {
          display: flex;

          &-title {
            font-size: 14px;
            font-weight: 700;
            margin-left: $gap * -0.75;
          }
        }

        &-body {
          font-size: 12px;
          margin: 0;
        }
      }

      &-input:checked + &-label{
        background-color: rgba(133, 92, 248, 0.2);
        border: 1px solid $colorLightPurple;
      }
    }

    &-theme {
      &-title {
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: $gap * 0.5;
      }

      &-field {
      display: flex;
      flex-direction: row;
      width: 100%;
      border-radius: 8px;
      border: 1px solid #9991A4;

        &-input {
          display: none;
        }

        &-label {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-grow: 1;
          font-weight: 700;
          color: #fff;
          height: 37px;
          cursor: pointer;

          &:first-of-type {
            border-radius: 8px 0 0 8px;
          }

          &:last-of-type {
            border-radius: 0 7px 7px 0;
          }
        }

        &-input:checked + &-label{
          color: #FFFFFF;
          background-color: #855CF8;
        }
      }
    }

    &-code {
      /deep/ textarea {
        opacity: 1;
        resize: none;
        height: 130px;
        min-height: 130px;
        -webkit-text-fill-color: #FFFFFF;
        background-color: #32293D;
        border: none;
        border-radius: 4px;
        font-family: $fontFamily;
      }
    }
  }

  &__body-code {
    display: flex;
  }

  &__footer {
    &-btn {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: $gap * 0.5;
      border-radius: 4px;
      background-color: #FFFFFF;
      border: 1px solid #DFDFDF;
      padding: $gap * 0.5 $gap * 1.5;
      cursor: pointer;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      color: #2D2D2D;

      @include display-less(phablet) {
        width: 100%;
      }

      &-icon {
        fill: #2D2D2D;
      }
    }
  }
}
</style>
