<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0h40v40H0z" />
    <rect
      x="2"
      y="2"
      width="36"
      height="36"
      rx="4"
      fill="#fff"
    />
    <path d="M26.873 10c-2.824 0-5.355 1.479-6.847 3.67C18.561 11.505 16.03 10 13.18 10 8.65 10 5 13.617 5 18.105c0 2.851 1.812 4.884 3.703 6.785l7.407 6.944c.4.37 1.039.08 1.039-.449v-5.412c0-1.558 1.279-2.825 2.85-2.825 1.573 0 2.852 1.267 2.852 2.825v5.412c0 .528.639.792 1.039.449l7.406-6.944C33.188 22.99 35 20.956 35 18.105 35.053 13.617 31.403 10 26.873 10ZM12.62 24.362a.532.532 0 0 1-.426.185.64.64 0 0 1-.374-.132c-2.45-2.138-3.676-4.383-3.676-6.653v-.026c0-1.954 1.199-4.172 2.797-5.149a.565.565 0 0 1 .773.185c.16.264.08.607-.187.766-1.278.792-2.264 2.64-2.264 4.198v.026c0 1.927 1.119 3.881 3.303 5.808.24.185.267.555.054.792Zm3.65-2.085a.572.572 0 0 1-.506.29.453.453 0 0 1-.267-.08c-1.652-.923-2.77-2.718-2.77-4.487v-.027c0-1.69.932-3.22 2.45-4.013a.608.608 0 0 1 .773.238.597.597 0 0 1-.24.765 3.355 3.355 0 0 0-1.838 3.01c0 1.373.88 2.799 2.185 3.512.266.158.346.501.213.792Zm3.756-1.453a2.434 2.434 0 0 1-2.45-2.428 2.434 2.434 0 0 1 2.45-2.43 2.434 2.434 0 0 1 2.451 2.43c.027 1.346-1.092 2.428-2.45 2.428Zm4.556 1.664a.518.518 0 0 1-.266.08.605.605 0 0 1-.506-.291c-.16-.264-.054-.608.213-.766 1.305-.74 2.184-2.139 2.184-3.511 0-1.268-.719-2.43-1.838-3.01-.266-.132-.373-.475-.24-.766.133-.264.48-.37.773-.237A4.51 4.51 0 0 1 27.353 18v.026c0 1.742-1.119 3.538-2.77 4.462Zm7.354-4.752c0 2.27-1.226 4.514-3.677 6.653a.501.501 0 0 1-.373.132.532.532 0 0 1-.426-.185.548.548 0 0 1 .053-.792c2.185-1.927 3.304-3.881 3.304-5.808v-.027c0-1.584-.986-3.432-2.265-4.198a.554.554 0 0 1-.187-.765.565.565 0 0 1 .773-.185c1.599 1.003 2.797 3.22 2.797 5.148v.027Z" fill="#DB0436" />
  </svg>
</template>

<script>
export default {}
</script>
