<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_204_1298)">
      <rect width="24" height="24" rx="6" fill="white" />
      <path d="M7.32 6.39C5.133 8.409 4.935 11.8778 7.31025 14.1135C7.51241 14.3016 7.78045 14.4027 8.05648 14.3948C8.33251 14.3869 8.59437 14.2708 8.7855 14.0715C8.87962 13.9735 8.95328 13.8578 9.00219 13.7311C9.0511 13.6043 9.07427 13.4691 9.07036 13.3333C9.06645 13.1975 9.03554 13.0639 8.97942 12.9402C8.9233 12.8164 8.8431 12.7051 8.7435 12.6128C7.27875 11.2358 7.404 9.144 8.733 7.9125C9.15225 7.5195 9.183 6.867 8.7855 6.453C8.409 6.0285 7.73925 6.00825 7.32075 6.39075L7.32 6.39Z" fill="#F90000" />
      <path d="M6.786 17.0843C7.20525 16.5668 7.10025 15.9458 6.57675 15.6345C4.902 14.496 3.85575 12.3218 3.85575 10.251C3.85575 8.0775 4.797 6.21375 6.57675 4.86825C7.10025 4.55775 7.10025 3.83325 6.78675 3.41925C6.4725 2.90175 5.73975 2.90175 5.3205 3.2115C3.018 4.9725 1.6575 7.353 1.7625 10.2525C1.8675 13.0478 3.228 15.7395 5.32125 17.292C5.53125 17.3955 5.7405 17.4998 5.94975 17.4998C6.26325 17.4998 6.5775 17.3955 6.78675 17.085L6.786 17.0843ZM15.252 12.6015C15.0543 12.793 14.9401 13.0548 14.9342 13.33C14.9283 13.6052 15.0312 13.8717 15.2205 14.0715C15.4115 14.2714 15.6735 14.3879 15.9499 14.3958C16.2262 14.4036 16.4945 14.3022 16.6965 14.1135C19.0725 11.877 18.873 8.409 16.686 6.39075C16.4826 6.20365 16.2136 6.10419 15.9374 6.11387C15.6612 6.12355 15.3998 6.24161 15.21 6.4425C15.1165 6.54121 15.0436 6.65755 14.9956 6.78474C14.9476 6.91192 14.9253 7.0474 14.9302 7.18326C14.9351 7.31912 14.967 7.45265 15.0241 7.57606C15.0811 7.69946 15.1622 7.81026 15.2625 7.902C16.602 9.1335 16.7175 11.2245 15.252 12.6015Z" fill="#F90000" />
      <path d="M22.2495 10.2525C22.3432 7.353 20.9932 4.9725 18.6907 3.21225C18.2715 2.90175 17.5395 2.90175 17.2252 3.42C16.9102 3.834 16.9102 4.5585 17.4345 4.869C19.2135 6.2145 20.1555 8.0775 20.1555 10.2518C20.1555 12.3218 19.1092 14.4968 17.4345 15.6353C16.911 15.945 16.806 16.5668 17.2245 17.0843C17.4345 17.3948 17.748 17.499 18.0622 17.499C18.2722 17.499 18.4807 17.3948 18.6907 17.2913C20.8882 15.7388 22.2495 13.0463 22.2495 10.2518V10.2525ZM10.7992 12.1493L8.25525 24H10.464L12.9802 12.2775C13.4295 12.0615 13.7943 11.7024 14.0174 11.2565C14.2405 10.8107 14.3092 10.3034 14.2127 9.81435C14.1162 9.32527 13.8601 8.88206 13.4844 8.55433C13.1088 8.22659 12.6349 8.03289 12.1373 8.00362C11.6396 7.97436 11.1464 8.11119 10.7349 8.39265C10.3234 8.6741 10.0171 9.08422 9.86399 9.55864C9.71086 10.033 9.71963 10.5449 9.88891 11.0138C10.0582 11.4827 10.3784 11.8821 10.7992 12.1493Z" fill="#F90000" />
    </g>
    <defs>
      <clipPath id="clip0_204_1298">
        <rect width="24" height="24" rx="6" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {}
</script>
