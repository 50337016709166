<template lang="html">
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="6" fill="black" />
    <path d="M3.51967 10.5157C3.80549 10.5157 4.03934 9.5738 4.03934 8.41104C4.03934 7.24829 3.80549 6.30639 3.51967 6.30639C3.23385 6.30639 3 7.24829 3 8.41104C3 9.5738 3.23385 10.5157 3.51967 10.5157Z" fill="#A238FF" />
    <path d="M18.7784 4.16925C18.5056 4.16925 18.2652 4.77337 18.0964 5.74125C17.8235 4.08481 17.3883 3 16.8946 3C16.31 3 15.7968 4.52652 15.5565 6.74811C15.3161 5.13713 14.9589 4.10429 14.5626 4.10429C14.004 4.10429 13.5233 6.131 13.3479 8.95669C13.0166 7.50812 12.5359 6.5987 12.0032 6.5987C11.4706 6.5987 10.9899 7.50812 10.6586 8.95669C10.4767 6.131 10.0025 4.10429 9.43739 4.10429C9.03464 4.10429 8.67737 5.13713 8.44352 6.7546C8.20317 4.52652 7.6965 3 7.10538 3C6.61169 3 6.16997 4.08481 5.90364 5.74774C5.73475 4.77986 5.4944 4.16925 5.22158 4.16925C4.7149 4.16925 4.30566 6.2804 4.30566 8.87874C4.30566 11.4771 4.7149 13.5817 5.22158 13.5817C5.42945 13.5817 5.62432 13.2245 5.77373 12.6268C6.01407 14.8224 6.52075 16.3295 7.10538 16.3295C7.56008 16.3295 7.96283 15.4266 8.23565 13.9975C8.42403 16.7127 8.89173 18.642 9.43739 18.642C9.78167 18.642 10.0935 17.8755 10.3273 16.6348C10.6066 19.2006 11.2497 21 12.0032 21C12.7568 21 13.3999 19.2006 13.6792 16.6348C13.913 17.8755 14.2248 18.642 14.5691 18.642C15.1148 18.642 15.5825 16.7127 15.7708 13.9975C16.0437 15.4266 16.4464 16.3295 16.9011 16.3295C17.4857 16.3295 17.9924 14.8224 18.2328 12.6268C18.3887 13.2245 18.577 13.5817 18.7849 13.5817C19.2916 13.5817 19.7008 11.4771 19.7008 8.87874C19.6878 6.2804 19.2786 4.16925 18.7784 4.16925Z" fill="#A238FF" />
    <path d="M20.4803 10.5157C20.7661 10.5157 21 9.5738 21 8.41104C21 7.24829 20.7661 6.30639 20.4803 6.30639C20.1945 6.30639 19.9606 7.24829 19.9606 8.41104C19.9606 9.5738 20.1945 10.5157 20.4803 10.5157Z" fill="#A238FF" />
  </svg>
</template>

<script>
export default {}
</script>
