<template lang="pug">
.podcast__action
  .button-wrapper(
    :class="{'button-wrapper_white': white}"
  )
    .notifications
      .notifications__single(
        v-if="notifications && notificationIsOpened"
      ) {{ $t('custom_notifications.copy_rss_feed') }}
    RssUiButton.button-wrapper-ui(
      outlined
      type="primary"
      :isRssFeedButton="true"
      @on-click="copyRSSFeed"
    ) {{ $t('form.buttons.global__copy_rss_feed') }}
</template>

<script>
import { mapGetters } from 'vuex'

import toast from '@/lib/toast'
import { config } from '@/config'
import { copyToClipboard } from '@/lib/utils.js'

export default {
  props: {
    notifications: {
      type: Boolean,
      default: false
    },

    white: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      notificationsTimer: null,
      notificationIsOpened: false,
      customNotificationInterval: 4000 // per ms
    }
  },

  computed: {
    ...mapGetters('podcasts', ['podcastByPath']),

    podcastSlug () {
      return this.$route.params.slug
    },

    currentPodcast () {
      return this.podcastByPath(this.podcastSlug)
    },

    rssFeedUrl () {
      return `${config.mediaUrl}/${this.podcastSlug}/feed.xml`
    }
  },

  beforeDestroy () {
    this.notificationIsOpened = false
    clearTimeout(this.notificationsTimer)
  },

  methods: {
    copyRSSFeed () {
      clearTimeout(this.notificationsTimer)
      const url = `${config.mediaUrl}/${this.podcastSlug}/feed.xml`
      copyToClipboard(url)
      toast(this.$t('toast_notifications.success.rss_feed__copied'), 'success')

      if (!this.currentPodcast) {
        return
      }

      const existingPodcastEpisodes = this.currentPodcast.episodes
      if (!existingPodcastEpisodes || existingPodcastEpisodes.length === 0) {
        this.notificationIsOpened = true
        this.notificationsTimer = setTimeout(() => {
          this.notificationIsOpened = false
        }, this.customNotificationInterval)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.button-wrapper {
  display: flex;
  position: relative;
  justify-content: center;

  &-ui {
    width: 100%;
  }

  &_white {
    /deep/ svg {
      path {
        fill: #fff;
      }
    }

    /deep/ .rss-ui-button {
      border-radius: 8px;

      &__text {
        font-weight: 600;
      }
    }
  }

  /deep/ .rss-ui-button {
    border: 1px solid #C2BEC8!important;
    &__text {
      color: #fff;
    }

    &:hover {
      border-color: #fff;
      background-color: transparent;
    }
  }
}
.notifications {
  bottom: 100%;
  position: absolute;
  text-align: center;

  &__single {
    z-index: 2;
    color: #fff;
    width: 300px;
    font-size: 14px;
    line-height: 16px;
    position: relative;
    padding: 12px $gap;
    border-radius: 4px;
    margin-bottom: 20px;
    background-color: transparent;

    &:before {
      width: 0;
      left: 50%;
      height: 0;
      content: '';
      bottom: -11px;
      margin-left: -12px;
      position: absolute;
      border-style: solid;
      border-width: 11px 12px 0 12px;
      border-color: $colorAccent transparent transparent transparent;
    }
  }
}
</style>
