<template>
  <svg
    viewBox="0 0 300 300"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <linearGradient
        id="applePodcastsIconId1"
      >
        <stop
          offset="0"
          stop-color="#822cbe"
        />
        <stop
          offset="1"
          stop-color="#d772fb"
        />
      </linearGradient>
      <linearGradient
        id="applePodcastsIconId2"
        x1="458.9"
        x2="456.36"
        y1="303.81"
        xlink:href="#applePodcastsIconId1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(-309.21)"
      />
    </defs>
    <rect
      width="300"
      height="300"
      fill="url(#applePodcastsIconId2)"
      ry="66.74"
    />
    <path
      fill="#fff"
      d="M140.84 262.64c-8.95-3.2-10.87-7.55-14.54-33.04-4.27-29.63-5.2-47.97-2.73-53.78 3.28-7.7 12.18-12.07 24.63-12.12 12.35-.05 21.33 4.36 24.63 12.12 2.48 5.8 1.55 24.15-2.72 53.78-2.9 20.7-4.5 25.93-8.5 29.45-5.5 4.87-13.3 6.22-20.7 3.6zm-38.23-30.4c-30.9-15.2-50.7-40.92-57.9-75.14-1.8-8.83-2.1-29.9-.4-38 4.5-21.75 13.1-38.76 27.4-53.8 20.6-21.72 47.1-33.2 76.6-33.2 29.2 0 55.6 11.27 75.7 32.34 15.3 15.9 23.9 32.73 28.3 54.9 1.5 7.38 1.5 27.5.1 35.8-4.6 26.24-19.2 50.14-40.5 66.2-7.6 5.74-26.2 15.76-29.2 15.76-1.1 0-1.2-1.14-.7-5.75.9-7.4 1.8-8.94 6-10.7 6.7-2.8 18.1-10.92 25.1-17.94 12.1-12 21-27.7 25.1-44.2 2.6-10.3 2.3-33.2-.6-43.8-9.1-33.7-36.6-59.9-70.3-66.9-9.8-2-27.6-2-37.5 0-34.1 7-62.3 34.5-70.9 69.1-2.3 9.4-2.3 32.3 0 41.7 5.7 22.9 20.5 43.9 39.9 56.4 3.8 2.5 8.4 5.1 10.3 5.9 4.2 1.8 5.1 3.3 5.9 10.7.5 4.5.4 5.8-.7 5.8-.7 0-5.8-2.2-11.2-4.8zm.4-40.68c-10.4-8.3-19.6-23.02-23.4-37.46-2.3-8.72-2.3-25.3.1-34 6.3-23.48 23.6-41.68 47.6-50.23 8.2-2.9 26.4-3.55 36.5-1.32 34.8 7.75 59.5 42.6 54.7 77.17-1.9 13.93-6.7 25.37-15.2 36-4.2 5.37-14.4 14.38-16.2 14.38-.3 0-.6-3.4-.6-7.54V181l5.2-6.2c19.6-23.48 18.2-56.28-3.2-77.8-8.3-8.38-17.9-13.3-30.3-15.57-8-1.48-9.7-1.48-18.1-.1-12.75 2.08-22.63 7.02-31.4 15.7-21.5 21.3-22.9 54.27-3.3 77.77l5.16 6.2v7.6c0 4.2-.33 7.6-.74 7.6-.4 0-3.3-2-6.4-4.5zm34.7-40.83c-8.9-4.14-13.7-11.95-13.8-22.13 0-9.15 5.1-17.13 13.9-21.8 5.6-2.94 15.5-2.94 21.1.02 6.1 3.17 11.1 9.32 13 15.74 5.8 19.72-15.1 37-34 28.17z"
    />
    <circle
      cx="149.89"
      cy="129.67"
      r="24.94"
      fill="#fff"
    />
    <path
      fill="#fff"
      d="M152.28 164.27c1.73.23 5.17.68 8.35 1.7 3.2 1 6.1 2.57 8.36 4.18 2.2 1.6 3.8 3.26 4.8 5.28 1 2.02 1.5 4.42 1.8 8.35.2 3.94.2 9.4-.7 19.6-.9 10.17-2.7 25.08-4.1 34.63-1.4 9.6-2.4 13.8-3.7 16.9-1.3 3.2-3 5.3-4.9 6.8-1.9 1.5-4.1 2.4-6.2 2.9-2.1.5-4 .5-5.8.5-1.8 0-3.5 0-5.8-.5s-5.3-1.4-7.5-3.2c-2.3-1.8-3.8-4.4-5-7.8-1.2-3.4-2.1-7.5-3.3-15.7-1.2-8.2-2.8-20.4-3.8-29.7s-1.4-15.7-1.48-20c-.1-4.3.1-6.43.6-8.5.5-2 1.3-3.9 2.4-5.5 1.1-1.6 2.54-3 4-4.1 1.43-1.1 2.9-1.9 4.66-2.52 1.74-.7 3.83-1.3 6.4-1.8 2.6-.5 5.6-1 7.14-1.2 1.54-.23 1.54-.23 3.26 0z"
    />
  </svg>
</template>

<script>
export default {}
</script>
