<template>
  <a
    class="link"
    :href="link"
    rel="noindex"
    target="blank"
  >
    <div class="link__icon">
      <slot />
    </div>
    <div class="link__content">
      <span>{{ $t('pages.distribution.listen_on') }}</span>
      <strong>{{ name }}</strong>
    </div>
  </a>
</template>

<script>
export default {
  props: {
    link: {
      type: String,
      default: ''
    },

    name: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.link {
  outline: none;
  display: flex;
  padding: 8px 12px;
  border-radius: 8px;
  text-decoration: none;
  background: #1D2526;
  border: 1px solid #656565;

  &__icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;

    /deep/ svg {
      width: auto;
      height: 100%;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;

    > span {
      color: #fff;
      line-height: 1;
      font-size: 10px;
      font-weight: 600;
      margin-bottom: 2px;
    }

    > strong {
      color: #fff;
      line-height: 1;
      font-size: 14px;
    }
  }
}
</style>
