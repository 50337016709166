<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2921_5609)">
      <rect width="24" height="24" rx="6" fill="#FF7E00" />
      <path d="M24.9984 24.9984V12.7459L18.1571 5.90463C14.8015 2.549 9.27649 2.549 5.91275 5.90463C2.55712 9.26025 2.55712 14.7853 5.91275 18.149L12.7621 24.9984H24.9984Z" fill="url(#paint0_linear_2921_5609)" />
      <path d="M12 3.33306C7.23307 3.33306 3.33307 7.23306 3.33307 12C3.33307 15.3044 5.17501 18.175 7.93751 19.6375V12C7.93751 10.05 9.34557 8.37056 11.1875 8.04556V9.45444C10.1044 9.77944 9.29194 10.8081 9.29194 12.0544C9.28836 12.4111 9.35596 12.7648 9.49078 13.095C9.6256 13.4252 9.82494 13.7251 10.0771 13.9773C10.3293 14.2295 10.6293 14.4288 10.9594 14.5637C11.2896 14.6985 11.6434 14.7661 12 14.7625C13.1919 14.7625 14.2206 13.95 14.6 12.8669H16.0081C15.6294 14.7081 14.0044 16.1169 12.0544 16.1169C11.025 16.1169 10.05 15.7375 9.34557 15.0875V20.2875C10.2125 20.5581 11.1331 20.7206 12.0544 20.7206C13.8419 20.7206 15.5206 20.1794 16.9294 19.2044V20.6669H20.7206V12C20.6669 7.23306 16.7669 3.33306 12 3.33306ZM12 13.625C11.0794 13.625 10.375 12.9206 10.375 12C10.375 11.0794 11.0794 10.375 12 10.375C12.9206 10.375 13.625 11.0794 13.625 12C13.625 12.9206 12.9206 13.625 12 13.625ZM14.7081 12C14.7115 11.6434 14.6439 11.2897 14.509 10.9596C14.3741 10.6294 14.1748 10.3295 13.9226 10.0774C13.6705 9.8252 13.3706 9.62586 13.0404 9.491C12.7103 9.35614 12.3566 9.28846 12 9.29194V7.9375C14.2206 7.9375 16.0625 9.77944 16.0625 12H14.7081ZM17.1456 12C17.1456 9.18306 14.8169 6.85444 12 6.85444V5.5C15.575 5.5 18.5 8.425 18.5 12H17.1456Z" fill="white" />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_2921_5609"
        x1="5.91275"
        y1="5.90463"
        x2="25.0024"
        y2="24.9943"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#DE4326" />
        <stop offset="1" stop-color="#F07A2A" />
      </linearGradient>
      <clipPath id="clip0_2921_5609">
        <rect width="24" height="24" rx="6" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {}
</script>
