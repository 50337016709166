<template>
  <svg width="19" height="18" viewBox="0 0 19 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.04 7.364a.818.818 0 0 0-.819.818v7.364c0 .451.366.818.818.818h7.364a.818.818 0 0 0 .818-.819V8.183a.818.818 0 0 0-.818-.818H9.039Zm-2.455.818a2.455 2.455 0 0 1 2.454-2.455h7.364a2.455 2.455 0 0 1 2.454 2.455v7.364A2.454 2.454 0 0 1 16.403 18H9.039a2.455 2.455 0 0 1-2.454-2.454V8.181Z" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.312 1.636a.818.818 0 0 0-.818.819v7.363a.818.818 0 0 0 .818.818h.818a.818.818 0 1 1 0 1.637h-.818A2.455 2.455 0 0 1 .857 9.818V2.455A2.455 2.455 0 0 1 3.312 0h7.364a2.454 2.454 0 0 1 2.454 2.455v.818a.818.818 0 1 1-1.636 0v-.818a.818.818 0 0 0-.818-.819H3.312Z" />
  </svg>
</template>

<script>
export default {
}
</script>
