<template>
  <div class="share-modal">
    <Modal
      :title="$t('single_words.share')"
      :is-active="isActive"
      @onDismiss="close"
    >
      <div class="share-modal__content">
        <div class="share-modal__items">
          <div
            class="share-modal__item"
            @click="openEmbedEpisodeModal"
          >
            <IconCode class="share-modal__item-icon" />
            <span>{{ $t('single_words.embed') }}</span>
          </div>

          <ShareNetwork
            class="share-modal__item"
            network="twitter"
            :url="''"
            :title="shareTitle + ' ' + shareLink + ' via @rss'"
            :description="shareDescription"
            :quote="shareDescription"
          >
            <div class="share-modal__item-icon">
              <IconTwitter class="share-modal__item-icon-svg" />
            </div>
            <span>X</span>
          </ShareNetwork>

          <ShareNetwork
            class="share-modal__item"
            network="facebook"
            :url="shareLink"
            :title="shareTitle"
            :description="shareTitle"
            :quote="shareTitle + ' via RSS.com'"
            :hashtags="hashtags"
          >
            <div class="share-modal__item-icon">
              <IconFacebook />
            </div>
            <span>Facebook</span>
          </ShareNetwork>

          <ShareNetwork
            class="share-modal__item"
            network="linkedin"
            :title="shareTitle + ' via @rsscom'"
            :url="shareLink"
            :quote="shareDescription + ' via @rsscom'"
            :hashtags="hashtags"
          >
            <div class="share-modal__item-icon">
              <IconLinkedin class="share-modal__item-icon-svg" />
            </div>
            <span>Linkedin</span>
          </ShareNetwork>

          <ShareNetwork
            class="share-modal__item"
            network="pinterest"
            :url="shareLink"
            :title="shareTitle"
            :media="shareMedia"
          >
            <div class="share-modal__item-icon">
              <IconPinterest />
            </div>
            <span>Pinterest</span>
          </ShareNetwork>
        </div>

        <div class="share-modal__copy-url">
          <div class="share-modal__copy-url-link">
            {{ linkOfThePage }}
          </div>
          <button
            class="share-modal__copy-url-button"
            @click="copyUrl"
          >
            <IconCopy class="share-modal__copy-url-button-icon" />
            <div class="share-modal__copy-url-button-text">
              {{ $t('form.buttons.global__copy') }}
            </div>
          </button>
        </div>

        <div v-if="episodeId" class="share-modal__start-at">
          <hr class="share-modal__start-at-separator"></hr>
          <div class="share-modal__start-at-info">
            <input
              v-model="startAtCheckbox"
              type="checkbox"
              class="share-modal__start-at-info-check"
            ></input>
            <IconCheck v-if="startAtCheckbox" class="share-modal__start-at-info-icon" />
            <span>
              {{ $t('start_at') }}
            </span>
            <input
              v-model="startAtTime"
              class="share-modal__start-at-info-time"
              :class="{'share-modal__start-at-info-time-error': startAtCheckbox && invalidTimeSelection}"
              type="text"
              :disabled="!startAtCheckbox"
              @blur="startAtTime = formatTime(startAtTime)"
              @input="startAtTime = startAtTime.replace(/[^0-9:]/g, '')"
            ></input>
          </div>
          <div
            v-if="startAtCheckbox && invalidTimeSelection"
            class="share-modal__start-at-error"
          >
            Invalid time selection
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import toast from '@/lib/toast'
import { config } from '@/config'
import { copyToClipboard } from '@/lib/utils'

import Modal from '@/components/common/Modal/Modal'

import IconCode from '@/components/icons/dashboard/Code.vue'
import IconCopy from '@/components/icons/dashboard/Copy.vue'
import IconCheck from '@/components/icons/dashboard/Check.vue'

import IconTwitter from '@/components/icons/dashboard/socials/Twitter'
import IconFacebook from '@/components/icons/dashboard/socials/FacebookRounded'
import IconLinkedin from '@/components/icons/dashboard/socials/LinkedIn'
import IconPinterest from '@/components/icons/dashboard/socials/Pinterest'

export default {
  components: {
    Modal,

    IconCode,
    IconCopy,
    IconCheck,

    IconTwitter,
    IconFacebook,
    IconLinkedin,
    IconPinterest
  },

  props: {
    podcast: {
      type: Object,
      default: null
    },

    episode: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      startAtTime: '00:00:00',
      startAtCheckbox: false,
      hashtags: 'podcast,episode,rsscom,rss_podcasting'
    }
  },

  computed: {
    ...mapState('player', ['currentEpisodeId', 'currentTime']),

    episodeId () {
      return this.$route.params.episode
    },

    podcastSlug () {
      return this.$route.params.slug
    },

    isActive () {
      return Boolean(this.$route.query.share)
    },

    linkOfThePage () {
      const link = `${config.baseUrl}${this.$route.path}`
      if (this.startAtCheckbox && !this.invalidTimeSelection) {
        return `${link}?time=${this.timeToSeconds(this.startAtTime)}`
      }

      return link
    },

    // Share data
    shareTitle () {
      if (this.episode) {
        return this.episode.title.replace(/<[^>]*>?/gm, '')
      }
      return this.podcast.title.replace(/<[^>]*>?/gm, '')
    },

    shareDescription () {
      if (this.episode && this.episode.description) {
        return this.episode.description.replace(/<[^>]*>?/gm, '')
      }
      return this.podcast.description.replace(/<[^>]*>?/gm, '')
    },

    shareLink () {
      if (this.episode) {
        if (this.startAtCheckbox && !this.invalidTimeSelection) {
          return config.baseUrl + this.$locatedLink('/podcasts/' + this.podcastSlug + '/' + this.episodeId + '/?time=' + this.timeToSeconds(this.startAtTime))
        }
        return config.baseUrl + this.$locatedLink('/podcasts/' + this.podcastSlug + '/' + this.episodeId + '/')
      }
      return config.baseUrl + this.$locatedLink('/podcasts/' + this.podcastSlug + '/')
    },

    shareMedia () {
      if (this.episode) {
        return `${config.imageResizerUrl}/${this.podcastSlug}/1400/${this.episode.episode_cover}`
      }
      return `${config.imageResizerUrl}/${this.podcastSlug}/1400/${this.podcast.cover}`
    },

    invalidTimeSelection () {
      return this.timeToSeconds(this.startAtTime) >= this.episode.itunes_duration
    }
  },

  watch: {
    isActive (value) {
      if (value) {
        if (this.currentEpisodeId && this.episode && this.currentEpisodeId === this.episode.id) {
          this.startAtTime = this.formatTime(Math.floor(this.currentTime).toString())
        }
      } else {
        this.startAtCheckbox = false
        this.startAtTime = '00:00:00'
      }
    }
  },

  methods: {
    close () {
      this.$router.push({
        query: {
          share: undefined
        }
      })
    },

    copyUrl () {
      copyToClipboard(this.linkOfThePage)
      toast(this.$t('sentences.copied_to_clipboard'), 'success')
    },

    openEmbedEpisodeModal () {
      this.$router.push({
        query: {
          embedded: 'true',
          episode: this.episodeId,
          time: this.startAtCheckbox && !this.invalidTimeSelection ? this.timeToSeconds(this.startAtTime) : undefined
        }
      })
    },

    formatTime (currentTime) {
      if (!currentTime) {
        return '00:00:00'
      }

      const time = currentTime.split(':')
      let seconds = time.length >= 1 ? Number(time[time.length - 1]) : 0
      let minutes = time.length >= 2 ? Number(time[time.length - 2]) : 0
      let hours = time.length >= 3 ? Number(time[time.length - 3]) : 0

      const carrySeconds = Math.floor(seconds / 60)
      seconds -= (60 * carrySeconds)
      minutes += carrySeconds

      const carryMinutes = Math.floor(minutes / 60)
      minutes -= (60 * carryMinutes)
      hours += carryMinutes

      return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
    },

    timeToSeconds (currentTime) {
      if (!currentTime) {
        return 0
      }
      const time = currentTime.split(':')
      const seconds = time.length >= 1 ? Number(time[time.length - 1]) : 0
      const minutes = time.length >= 2 ? Number(time[time.length - 2]) : 0
      const hours = time.length >= 3 ? Number(time[time.length - 3]) : 0
      return hours * 3600 + minutes * 60 + seconds
    }
  }
}
</script>

<style lang="scss" scoped>
.share-modal {
  z-index: 1000000;
  &__content {
    display: flex;
    position: relative;
    flex-direction: column;
  }

  &__items {
    display: flex;
    grid-gap: 40px;
    justify-content: center;
    margin-top: $gap * 1.5;
    margin-right: $gap * 2;
    margin-left: $gap * 2;

    @include display-less(phablet) {
      flex-wrap: wrap;
      grid-gap: $gap * 2;
    }
  }

  &__item {
    color: #fff;
    display: flex;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    text-decoration: none;
    flex-direction: column;
    letter-spacing: -0.53px;

    &:hover {
      .share-modal__item-icon {
        transform: translateY(-6px);
      }
    }

    > span {
      margin-top: $gap * 0.5;
    }

    &-icon {
      width: 56px;
      height: 56px;
      display: flex;
      padding: 10px;
      cursor: pointer;
      border-radius: 56px;
      align-items: center;
      text-decoration: none;
      justify-content: center;
      background-color: #6c26d6;
      transition: transform 0.2s ease;

      &-svg {
        width: 24px;
        height: 24px;
      }

      /deep/ i {
        color: #fff;
        font-size: 1.6em;
      }
    }
  }

  &__copy-url {
    display: flex;
    gap: $gap * 0.5;
    margin-top: $gap * 1.5;
    margin-left: $gap;
    margin-right: $gap;
    margin-bottom: $gap;

    @include display-less(phablet) {
      flex-direction: column;
    }

    &-link {
      width: 100%;
      flex-grow: 0;
      padding: 7px 16px;
      border-radius: 4px;
      background: #ffffff;
      overflow-x: auto;
      white-space: nowrap;
      color: #979797;
      text-decoration: underline;
      border: 1px solid #dfdfdf;
      -ms-overflow-style: none; // Hide scrollbar for Edge
      scrollbar-width: none; // Hide scrollbar for Firefox
      &::-webkit-scrollbar {
        display: none; // Hide scrollbar for Chrome
      }

      @include display-less(phablet) {
        word-break: break-all;
        white-space: word-break;
        overflow-x: visible;
        white-space: normal;
      }
    }

    &-button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: $gap * 0.5;
      border-radius: 4px;
      background-color: #FFFFFF;
      border: 1px solid #DFDFDF;
      padding: $gap * 0.5 $gap * 1.5;
      cursor: pointer;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #2D2D2D;

      &-icon {
        fill: #2D2D2D;
      }
    }
  }

  &__start-at {
    margin-bottom: $gap * 1.5;
    margin-left: $gap;
    margin-right: $gap;

    &-separator {
      margin-top: $gap * 0.5;
    }

    &-info {
      display: flex;
      gap: $gap * 0.5;
      position: relative;
      align-items: center;

      &-check {
        appearance: none;
        width: 20px;
        height: 20px;
        border: 2px solid #dfdfdf;
        border-radius: 4px;
        cursor: pointer;
        &:checked {
          background-color: #6c26d6;
          border: 0;
        }
      }

      &-icon {
        position: absolute;
        width: 13px;
        height: 13px;
        top: 10px;
        left: 3px;
        pointer-events: none;
      }

      &-time {
        width: 77px;
        height: $gap * 2;
        padding: 8px 4px;
        border: 1px solid #DFDFDF;
        border-radius: 4px;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        font-family: inherit;
        text-align: center;
        color: #2D2D2D;
        &:focus {
          outline: none;
        }
        &:disabled {
          background-color: #F7F7F7;
          color: #797088;
        }
        &-error {
          border: 1px solid #E33636;
        }
      }
    }

    &-error {
      color: #E33636;
      font-size: 14px;
      margin-top: $gap * 0.5;
    }
  }
}
</style>
