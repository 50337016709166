<template>
  <svg
    width="25"
    height="24"
    fill="none"
    viewBox="0 0 25 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#fff"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.064 7.293a1 1 0 0 1 0 1.414L4.77 12l3.293 3.294a1 1 0 0 1-1.415 1.414l-4-4.002a1 1 0 0 1 0-1.414l4-3.998a1 1 0 0 1 1.415 0Zm9.585 0a1 1 0 0 1 1.415 0l4 4.002a1 1 0 0 1 0 1.414l-4 3.998a1 1 0 1 1-1.415-1.414L20.942 12 17.65 8.707a1 1 0 0 1 0-1.414Z"
    />
  </svg>
</template>

<script>
export default {
}
</script>
