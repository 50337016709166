<template>
  <div class="share-modal">
    <Modal
      :is-active="isActive"
      :title="`${$t('pages.distribution.listen_on')} ...`"
      @onDismiss="close"
    >
      <Box mb="6">
        <Paragraph color="white" size="large">
          {{ $t('pages.distribution.listen_on_text') }}
        </Paragraph>
      </Box>
      <ListenOnList grid :podcast="podcast" />
    </Modal>
  </div>
</template>

<script>
import Box from '@/components/common/Box/Box'
import Paragraph from '@/components/common/Paragraph/Paragraph'
import Modal from '@/components/common/Modal/Modal'

import ListenOnList from '@/components/shared/elements/distribution/list.vue'

export default {
  components: {
    Box,
    Modal,
    Paragraph,
    ListenOnList
  },

  props: {
    podcast: {
      type: Object,
      default: null
    }
  },

  computed: {
    isActive () {
      return this.$route.query['listen-on']
    }
  },

  methods: {
    close () {
      this.$router.push({
        query: {
          'listen-on': undefined
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.share-modal {
  &__content {
    display: flex;
    position: relative;
    border-radius: 4px;
    flex-direction: column;
    background-color: #fff;
    padding: $gap * 1.5 $gap * 2;
    box-shadow: 0px 0px 1px rgba(14, 8, 60, 0.08), 0px 4px 13px rgba(14, 8, 60, 0.12);
  }

  &__close {
    cursor: pointer;
    top: $gap * 1.5;
    right: $gap * 1.5;
    position: absolute;
  }

  &__title {
    color: #2D2D2D;
    font-size: 22px;
    font-weight: 700;
    line-height: 1.1;
    margin-bottom: $gap * 0.5;
  }

  &__text {
    color: #2D2D2D;
    font-size: 16px;
    line-height: 1.1;
    font-weight: 400;
    margin-bottom: 1.5 * $gap;
  }
}
</style>
