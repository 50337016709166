<template>
  <div :class="classes">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    mb: {
      type: String,
      default: '0'
    },
    mt: {
      type: String,
      default: '0'
    }
  },

  computed: {
    classes () {
      return [
        'commonBox',
        `commonBox_mb-${this.mb}`,
        `commonBox_mt-${this.mt}`
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.commonBox {
  &_mb-1 {
    margin-bottom: 4px;
  }
  &_mb-2 {
    margin-bottom: 8px;
  }
  &_mb-4 {
    margin-bottom: 16px;
  }
  &_mb-6 {
    margin-bottom: 24px;
  }
  &_mb-8 {
    margin-bottom: 32px;
  }
  &_mb-10 {
    margin-bottom: 40px;
  }

  &_mt-1 {
    margin-top: 4px;
  }
  &_mt-2 {
    margin-top: 8px;
  }
  &_mt-4 {
    margin-top: 16px;
  }
  &_mt-6 {
    margin-top: 24px;
  }
  &_mt-8 {
    margin-top: 32px;
  }
  &_mt-10 {
    margin-top: 40px;
  }
}
</style>
