<template>
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.3334 0.166504C10.7016 0.166504 11 0.390361 11 0.666504V1.49984H12.3334C13.0698 1.49984 13.6667 2.09679 13.6667 2.83317V13.4998C13.6667 14.2362 13.0698 14.8332 12.3334 14.8332H1.66671C0.930328 14.8332 0.333374 14.2362 0.333374 13.4998V2.83317C0.333374 2.09679 0.930328 1.49984 1.66671 1.49984H3.00004V0.666504C3.00004 0.390361 3.29852 0.166504 3.66671 0.166504C4.0349 0.166504 4.33337 0.390361 4.33337 0.666504V1.49984H9.66671V0.666504C9.66671 0.390361 9.96518 0.166504 10.3334 0.166504ZM3.00004 2.83317V3.6665C3.00004 3.94265 3.29852 4.1665 3.66671 4.1665C4.0349 4.1665 4.33337 3.94265 4.33337 3.6665V2.83317H9.66671V3.6665C9.66671 3.94265 9.96518 4.1665 10.3334 4.1665C10.7016 4.1665 11 3.94265 11 3.6665V2.83317H12.3334V5.49984H1.66671V2.83317H3.00004ZM12.3334 6.83317V13.4998H1.66671V6.83317H12.3334Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {}
</script>
