<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2921_5593)">
      <rect width="24" height="24" rx="6" fill="black" />
      <path d="M10.9425 21.8332C11.0359 21.8306 11.1245 21.7919 11.19 21.7252C11.226 21.6903 11.2543 21.6481 11.2727 21.6014C11.2912 21.5548 11.2996 21.5047 11.2972 21.4545C11.2949 21.4044 11.2819 21.3553 11.2592 21.3106C11.2364 21.2658 11.2044 21.2264 11.1652 21.195C9.31275 19.7152 9.228 17.7885 9.228 17.2612C9.228 15.7687 9.843 14.2792 11.133 14.2792C12.0105 14.2792 12.216 14.9797 13.542 14.9797C13.9203 14.98 14.2949 14.9058 14.6445 14.7613C14.9941 14.6168 15.3117 14.4048 15.5794 14.1375C15.847 13.8702 16.0594 13.5528 16.2044 13.2034C16.3493 12.854 16.4241 12.4795 16.4242 12.1012C16.4242 11.0902 15.6525 9.21525 13.5022 9.21525C11.3512 9.21525 7.66575 11.7645 7.66575 16.017C7.66575 19.7715 10.596 21.8332 10.9417 21.8332H10.9425Z" fill="#FA9F23" />
      <path d="M16.425 5.12775C16.4247 5.50602 16.3499 5.88052 16.2048 6.22988C16.0598 6.57923 15.8473 6.89659 15.5796 7.16382C15.3119 7.43106 14.9941 7.64293 14.6445 7.78735C14.2949 7.93176 13.9203 8.00589 13.542 8.0055C12.9105 8.0055 12.5333 7.84725 12.2168 7.68075C11.868 7.49775 11.5928 7.30575 11.133 7.30575C10.158 7.30575 9.56925 8.15475 9.33975 9.21375C8.784 9.71317 8.30821 10.2949 7.929 10.9387C7.75364 10.3221 7.66529 9.68389 7.6665 9.04275C7.6665 4.79025 11.3895 2.241 13.5023 2.241C15.6158 2.241 16.425 4.116 16.425 5.12775Z" fill="url(#paint0_linear_2921_5593)" />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_2921_5593"
        x1="12.0458"
        y1="2.241"
        x2="12.0458"
        y2="10.9387"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFC900" />
        <stop offset="0.6" stop-color="#FCAE17" />
        <stop offset="1" stop-color="#FA9F23" />
      </linearGradient>
      <clipPath id="clip0_2921_5593">
        <rect width="24" height="24" rx="6" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {}
</script>
