<template lang="pug">
  a.custom-website-btn(
    v-if="podcastCustomWebsite"
    :href="podcastCustomWebsite"
    rel="noopener nofollow"
    target="blank"
  )
    IconLink
    template {{ $t('single_words.website') }}
</template>

<script>
import IconLink from '@/components/icons/default/Link.vue'

export default {
  components: {
    IconLink
  },

  props: {
    podcastData: {
      type: Object,
      default: null
    }
  },

  computed: {
    podcastSlug () {
      return this.$route.params.slug
    },

    podcastCustomWebsite () {
      if (!this.podcastData) {
        return null
      }
      return this.podcastData.custom_link
    }
  }
}
</script>

<style lang="scss">
.custom-website-btn {
  color: #fff;
  display: flex;
  font-weight: 600;
  padding: 7px 8px;
  grid-gap: $gap / 2;
  border-radius: 8px;
  padding-right: $gap;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  box-sizing: border-box;
  transition: all .2s ease;
  border: 1px solid #C2BEC8;

  &:hover {
    color: #fff;
    border-color: #fff;
  }
}
</style>
