<template>
  <p :class="classes">
    <slot />
  </p>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: 'medium'
    },
    color: {
      type: String,
      default: 'white'
    },
    isWithoutMargin: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    classes () {
      return [
        'commonParagraph',
        `commonParagraph_${this.size ? this.size : 'medium'}`,
        `commonParagraph_${this.color ? this.color : 'white'}`,
        { 'commonParagraph_without-margin': this.isWithoutMargin }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.commonParagraph {
  &_medium {
    font-size: 16px;
  }

  &_large {
    font-size: 18px;
  }

  &_white {
    color: #fff;
  }

  &_without-margin {
    margin: 0;
  }
}
</style>
