<template>
  <svg
    fill="none"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#fff"
      d="M10 1.666c-4.595 0-8.333 3.738-8.333 8.333 0 4.595 3.738 8.334 8.333 8.334 4.595 0 8.334-3.739 8.334-8.334S14.595 1.666 10 1.666zm0 15.45A7.125 7.125 0 012.884 10c0-3.924 3.192-7.116 7.116-7.116 3.925 0 7.117 3.192 7.117 7.116 0 3.925-3.193 7.117-7.117 7.117z"
    />
    <path
      fill="#fff"
      d="M12.839 10h-2.636V6.35a.608.608 0 10-1.217 0v4.258c0 .336.273.609.609.609h3.244a.608.608 0 100-1.217z"
    />
  </svg>
</template>

<script>
export default {}
</script>
