<template>
  <svg viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.498173 4.17672C0.273692 4.4078 0.279044 4.77711 0.510128 5.00159L3.04635 7.46535C3.27507 7.68753 3.63982 7.68489 3.86529 7.45942L9.66239 1.66232C9.8902 1.43451 9.8902 1.06516 9.66239 0.837358C9.43458 0.609552 9.06524 0.609552 8.83743 0.837358L3.44688 6.22791L1.32304 4.16477C1.09196 3.94028 0.722653 3.94564 0.498173 4.17672Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {}
</script>
