<template lang="pug">
  a.donate-btn(
    v-if="podcastFundingUrl"
    :href="podcastFundingUrl"
    rel="noopener nofollow"
    target="blank"
  )
    IconDonate
    template {{ $t('single_words.donate') }}
</template>

<script>
import IconDonate from '@/components/icons/dashboard/NonProfitStroke'

export default {
  components: {
    IconDonate
  },

  props: {
    podcastData: {
      type: Object,
      default: null
    }
  },

  computed: {
    podcastSlug () {
      return this.$route.params.slug
    },

    podcastFundingUrl () {
      if (!this.podcastData) {
        return null
      }
      return this.podcastData.podcast_funding
    }
  }
}
</script>

<style lang="scss" scoped>
.donate-btn {
  color: #fff;
  display: flex;
  font-weight: 600;
  padding: 7px 8px;
  grid-gap: $gap / 2;
  border-radius: 8px;
  padding-right: $gap;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  box-sizing: border-box;
  transition: all .2s ease;
  border: 1px solid #C2BEC8;

  &:hover {
    color: #fff;
    border-color: #fff;
  }
}
</style>
