<template lang="html">
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2921_5588)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5776 0.226435L2.82418 5.40257C2.36547 5.67392 2.02202 6.27509 2.02202 6.80749V17.1864C2.02202 17.7188 2.36547 18.32 2.82418 18.5913L11.5776 23.7684C12.0415 24.0426 12.7364 24.0426 13.1997 23.7684L21.9527 18.5913C22.4114 18.32 22.7549 17.7188 22.7549 17.1864V6.80749C22.7549 6.27509 22.4114 5.67392 21.9527 5.40257L13.1997 0.226435C12.7364 -0.0477174 12.041 -0.0477174 11.5776 0.226435Z" fill="url(#paint0_linear_2921_5588)" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5776 0.226435L2.82418 5.40257C2.36547 5.67392 2.02202 6.27509 2.02202 6.80749V17.1864C2.02202 17.7188 2.36547 18.32 2.82418 18.5913L11.5776 23.7684C12.0415 24.0426 12.7364 24.0426 13.1997 23.7684L21.9527 18.5913C22.4114 18.32 22.7549 17.7188 22.7549 17.1864V6.80749C22.7549 6.27509 22.4114 5.67392 21.9527 5.40257L13.1997 0.226435C12.7364 -0.0477174 12.041 -0.0477174 11.5776 0.226435Z" fill="url(#paint1_linear_2921_5588)" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M13.8173 8.89731C13.4246 8.89731 13.106 9.19907 13.106 9.57147V11.2033C13.106 11.4096 12.9149 11.5766 12.6792 11.5766C12.4435 11.5766 12.2523 11.4096 12.2523 11.2033V8.9834C12.2523 8.61053 11.9337 8.30878 11.5411 8.30878C11.148 8.30878 10.8298 8.61053 10.8298 8.9834V10.7626C10.8298 10.9684 10.6387 11.1359 10.403 11.1359C10.1678 11.1359 9.97659 10.9684 9.97659 10.7626V10.1179C9.97659 9.7455 9.65798 9.44328 9.26533 9.44328C8.87222 9.44328 8.55407 9.74503 8.55407 10.1179V11.6445C8.55407 11.8503 8.36291 12.0178 8.12723 12.0178C7.89201 12.0178 7.70085 11.8503 7.70085 11.6445V11.4671C7.70085 11.0774 7.38223 10.7612 6.98959 10.7612C6.59647 10.7612 6.27833 11.0774 6.27833 11.4671V13.586C6.27833 13.9757 6.59647 14.2924 6.98959 14.2924C7.38223 14.2924 7.70085 13.9761 7.70085 13.586V13.4087C7.70085 13.2028 7.89201 13.0353 8.12723 13.0353C8.36291 13.0353 8.55407 13.2028 8.55407 13.4087V15.8306C8.55407 16.203 8.87222 16.5053 9.26533 16.5053C9.65798 16.5053 9.97659 16.203 9.97659 15.8306V12.5268C9.97659 12.3209 10.1678 12.1535 10.403 12.1535C10.6387 12.1535 10.8298 12.3209 10.8298 12.5268V14.1914C10.8298 14.5642 11.148 14.866 11.5411 14.866C11.9337 14.866 12.2523 14.5642 12.2523 14.1914V12.9675C12.2523 12.7616 12.4435 12.5942 12.6792 12.5942C12.9144 12.5942 13.106 12.7616 13.106 12.9675V14.2756C13.106 14.648 13.4246 14.9502 13.8173 14.9502C14.2104 14.9502 14.5286 14.6484 14.5286 14.2756V9.57147C14.5286 9.19907 14.2104 8.89731 13.8173 8.89731Z" fill="white" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M16.0935 10.7195C15.7009 10.7195 15.3823 11.0213 15.3823 11.3942V14.0805C15.3823 14.4529 15.7004 14.7551 16.0935 14.7551C16.4862 14.7551 16.8048 14.4533 16.8048 14.0805V11.3942C16.8043 11.0213 16.4857 10.7195 16.0935 10.7195Z" fill="white" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3693 12.0856C17.9766 12.0856 17.658 12.3874 17.658 12.7602V13.4288C17.658 13.8012 17.9766 14.1034 18.3693 14.1034C18.7624 14.1034 19.0805 13.8016 19.0805 13.4288V12.7602C19.0805 12.3874 18.7624 12.0856 18.3693 12.0856Z" fill="white" />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_2921_5588"
        x1="-143.284"
        y1="2.98408"
        x2="2506.15"
        y2="2009.21"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFB768" />
        <stop offset="0.1939" stop-color="#FF9953" />
        <stop offset="0.5655" stop-color="#FF652E" />
        <stop offset="0.8457" stop-color="#FF4518" />
        <stop offset="1" stop-color="#FF390F" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2921_5588"
        x1="-3.98208"
        y1="11.9974"
        x2="14.7358"
        y2="28.1988"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF9E57" />
        <stop offset="1" stop-color="#FF5321" />
      </linearGradient>
      <clipPath id="clip0_2921_5588">
        <rect width="20.7568" height="24" fill="white" transform="translate(2)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {}
</script>
