<template>
  <BModal
    class="commonModal"
    :active="isActive"
    :scroll="scroll ? scroll : 'clip'"
    can-cancel="['escape', 'outside']"
    @onDismiss="onDismiss"
    @onCancel="onDismiss"
  >
    <div class="commonModal__wrapper">
      <div class="commonModal__header">
        <h3 class="commonModal__title">
          {{ title }}
        </h3>
        <button class="commonModal__close" @click="onDismiss">
          <Icon name="close" size="medium" color="white" />
        </button>
      </div>
      <div class="commonModal__content">
        <div class="commonModal__scrollable">
          <slot />
        </div>
      </div>
      <div v-if="$slots.footer" class="commonModal__footer">
        <slot name="footer" />
      </div>
    </div>
  </BModal>
</template>

<script>
import Icon from '@/components/common/Icon/Icon'

export default {
  components: {
    Icon
  },

  props: {
    scroll: {
      type: String,
      default: 'clip'
    },
    title: {
      type: String,
      default: ''
    },
    isActive: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    onDismiss () {
      this.$emit('onDismiss')
    }
  }
}
</script>

<style lang="scss">
.commonModal {
  .modal-content {
    display: flex;
    height: 100vh;
    max-height: 100vh;
    justify-content: center;
    align-items: center;

    @include display-less(tablet) {
      max-width: 100%;
      margin-top: auto;
      align-items: flex-end;
    }
  }
}
</style>

<style lang="scss" scoped>
.commonModal {
  &__wrapper {
    width: 640px;
    display: grid;
    max-height: 90vh;
    border-radius: 8px;
    flex-direction: column;
    border: 1px solid #423652;
    background-color: #221C2A;
    grid-template-rows: auto 1fr auto;

    @include display-less(tablet) {
      width: 100%;
      border-radius: 0;
      max-height: 100vh;
    }
  }

  &__header {
    padding: $gap;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #32293D;
    border-radius: 8px 8px 0 0;

    @include display-less(tablet) {
      border-radius: 0;
    }
  }

  &__title {
    color: #fff;
    font-size: 16px;
    font-weight: bold;
  }

  &__close {
    padding: 0;
    color: #fff;
    border: none;
    outline: none;
    cursor: pointer;
    background: none;
  }

  &__content {
    overflow: hidden auto;
    padding: $gap * 1.5 $gap;

    @include display-less(smart) {
      max-height: 80vh;
    }
  }

  &__footer {
    width: 100%;
    display: flex;
    padding: $gap;
    justify-content: flex-end;
    background-color: #32293D;
    border-radius: 0 0 8px 8px;
  }
}
</style>
