<template lang="pug">
a.play-pause-btn(
href="#"
@click.prevent="click"
)
  svg( v-if="loading" viewBox='0 0 56 56' fill='none' xmlns='http://www.w3.org/2000/svg')
    rect(width='56', height='56', rx='28', fill='url(#paint0_linear_645_13283)')
    path.play-pause-btn__loading-gear(fill-rule='evenodd', clip-rule='evenodd', d='M25.6704 14.29C28.3214 13.7626 31.0693 14.0333 33.5666 15.0677C36.0639 16.1021 38.1983 17.8538 39.7 20.1012C41.2017 22.3487 42.0033 24.991 42.0033 27.694C42.0033 28.6145 41.2571 29.3607 40.3366 29.3607C39.4161 29.3607 38.6699 28.6145 38.6699 27.694C38.6699 25.6503 38.0639 23.6524 36.9284 21.9531C35.793 20.2538 34.1792 18.9294 32.291 18.1473C30.4028 17.3652 28.3251 17.1605 26.3207 17.5592C24.3162 17.9579 22.475 18.9421 21.0298 20.3872C19.5847 21.8324 18.6005 23.6736 18.2018 25.6781C17.8031 27.6826 18.0077 29.7602 18.7898 31.6484C19.5719 33.5366 20.8964 35.1504 22.5957 36.2859C24.295 37.4213 26.2929 38.0273 28.3366 38.0273C29.2571 38.0273 30.0033 38.7735 30.0033 39.694C30.0033 40.6145 29.2571 41.3607 28.3366 41.3607C25.6336 41.3607 22.9913 40.5591 20.7438 39.0574C18.4963 37.5557 16.7446 35.4213 15.7102 32.924C14.6758 30.4268 14.4052 27.6789 14.9325 25.0278C15.4599 22.3767 16.7615 19.9415 18.6728 18.0302C20.5841 16.1189 23.0193 14.8173 25.6704 14.29Z', fill='white')
    defs
      linearGradient#paint0_linear_645_13283(x1='-39.1812', y1='44.4354', x2='29.4396', y2='103.924', gradientUnits='userSpaceOnUse')
        stop(stop-color='#F87900')
        stop(offset='0.51', stop-color='#FE4F45')
        stop(offset='1', stop-color='#E419BA')
  .play-pause-btn__svg(v-else)
    svg(v-if="!isPlaying" viewBox='0 0 56 56', fill='none', xmlns='http://www.w3.org/2000/svg')
      rect(width='56', height='56', rx='28', fill='url(#paint0_linear_604_13274)')
      path(d='M22.6445 18.3216C22.275 18.0864 21.8067 18.0712 21.4227 18.2819C21.0387 18.4927 20.8 18.8959 20.8 19.3339L20.8 38.0006C20.8 38.4385 21.0386 38.8417 21.4225 39.0525C21.8064 39.2632 22.2746 39.2481 22.6441 39.0131L37.3108 29.6834C37.657 29.4632 37.8666 29.0814 37.8667 28.6711C37.8668 28.2608 37.6572 27.8789 37.3111 27.6586L22.6445 18.3216Z', fill='white')
      defs
        linearGradient#paint0_linear_604_13274(x1='-39.1812', y1='44.4354', x2='29.4396', y2='103.924', gradientUnits='userSpaceOnUse')
          stop(stop-color='#F87900')
          stop(offset='0.51', stop-color='#FE4F45')
          stop(offset='1', stop-color='#E419BA')

    svg(v-else viewBox='0 0 56 56', fill='none', xmlns='http://www.w3.org/2000/svg')
      rect(width='56', height='56', rx='28', fill='white')
      path(d='M19.1667 16.6669C18.8906 16.6669 18.6667 16.8907 18.6667 17.1669V37.5002C18.6667 37.7763 18.8906 38.0002 19.1667 38.0002H24.567C24.8432 38.0002 25.067 37.7763 25.067 37.5002V17.1669C25.067 16.8907 24.8432 16.6669 24.567 16.6669H19.1667Z', fill='url(#paint0_linear_604_13273)')
      path(d='M31.9661 16.6671C31.6899 16.6671 31.4661 16.891 31.4661 17.1671V37.5004C31.4661 37.7766 31.6899 38.0004 31.9661 38.0004H37.3664C37.6425 38.0004 37.8664 37.7766 37.8664 37.5004V17.1671C37.8664 16.891 37.6425 16.6671 37.3664 16.6671H31.9661Z', fill='url(#paint0_linear_604_13273)')
      path(d='M19.1667 16.6669C18.8906 16.6669 18.6667 16.8907 18.6667 17.1669V37.5002C18.6667 37.7763 18.8906 38.0002 19.1667 38.0002H24.567C24.8432 38.0002 25.067 37.7763 25.067 37.5002V17.1669C25.067 16.8907 24.8432 16.6669 24.567 16.6669H19.1667Z', stroke='white')
      path(d='M31.9661 16.6671C31.6899 16.6671 31.4661 16.891 31.4661 17.1671V37.5004C31.4661 37.7766 31.6899 38.0004 31.9661 38.0004H37.3664C37.6425 38.0004 37.8664 37.7766 37.8664 37.5004V17.1671C37.8664 16.891 37.6425 16.6671 37.3664 16.6671H31.9661Z', stroke='white')
      defs
        linearGradient#paint0_linear_604_13273(x1='5.23348', y1='33.5948', x2='30.849', y2='53.5802', gradientUnits='userSpaceOnUse')
          stop(stop-color='#F87900')
          stop(offset='0.51', stop-color='#FE4F45')
          stop(offset='1', stop-color='#E419BA')
</template>

<script>
export default {
  props: {
    isPlaying: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    click () {
      this.$emit('on-click', !this.isPlaying)
    }
  }
}
</script>

<style lang="scss" scoped>
.play-pause-btn {
  outline: none;
  text-decoration: none;
  &__loading {
    &-gear {
      animation: spin 1s linear infinite;
      transform-origin: 50% 50%;
      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }
}
</style>
