<template>
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)" stroke="#fff" stroke-width="1.3">
      <path
        d="M23.047 10.46H15.12c-1.156 0-1.743.387-1.743.387l-6.122 4.6a.651.651 0 00-.103.118 1.093 1.093 0 00-.375.817c0 .611.495 1.106 1.106 1.106.252 0 .47-.101.657-.244v.025l.357-.251c.821-.531 4.155-2.688 4.44-2.853.324-.188.783-.163 1.134.324.405.81-.621 1.405-.621 1.405l-3.576 2.333c-.699.456-1.316.546-1.624.562l-5.043.022c-.082 0-.158.004-.236.008h-.387c-.01 0-.02-.007-.032-.007-.38 0-.7.207-.897.503l-.026.038c-.099.165-.171.348-.171.554 0 .604.49 1.095 1.094 1.095h8.257a4.317 4.317 0 002.222-.686l5.434-3.58c.018-.012.52-.344 1.436-.35.491-.004 1.178-.005 2.053.007h.047c.044 0 .12-.003.206-.015h.44c.605 0 1.094-.49 1.094-1.093v-3.73c0-.604-.489-1.094-1.093-1.094z"
      />
      <path
        stroke-linejoin="round"
        d="M2.23 5.903s.01.064.04.175v.004h0c.186.725 1.119 3.573 4.78 6.087l.001.011.36.238.206-.136.002-.008c3.847-2.58 4.771-5.55 4.939-6.227v-.003c.022-.09.032-.142.032-.142l-.012-.003c.02-.17.04-.46-.02-.716-.001-.007-.008-.005-.01-.012A2.585 2.585 0 0010 3a2.582 2.582 0 00-2.573 2.419h-.034A2.584 2.584 0 004.82 3a2.583 2.583 0 00-2.564 2.324c-.012.006-.021.023-.027.055-.028.162-.008.37.013.52l-.013.004z"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <path
          fill="#fff"
          transform="translate(.857)"
          d="M0 0h24v24H0z"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {}
</script>
