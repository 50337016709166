<template lang="pug">
.episode-wrp__share-elements
  a.share-btn(
    @click.prevent="openShareModal"
  )
    IconShareStroked.share-btn__icon
    span.share-btn__text {{ $t('single_words.share') }}
</template>

<script>
import IconCode from '@/components/icons/dashboard/Code.vue'
import IconShareStroked from '@/components/icons/dashboard/Share'

import toast from '@/lib/toast'
import { config } from '@/config'
import { copyToClipboard } from '@/lib/utils'

export default {
  components: {
    IconCode,
    IconShareStroked
  },

  data () {
    return {
      hashtags: 'podcast,episode,rsscom,rss_podcasting'
    }
  },

  methods: {
    copyPodcastPublicUrl () {
      const podcastUrl = `${config.baseUrl}${this.$route.path}`
      copyToClipboard(podcastUrl)
      toast(this.$t('sentences.copied_to_clipboard'), 'success')
    },

    openEmbedEpisodeModal () {
      this.$router.push({
        query: {
          embedded: 'true',
          episode: this.$route.params.episode
        }
      })
    },

    openShareModal () {
      this.$router.push({
        query: {
          share: true
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.episode-wrp {
  &__share {
    &-elements {
      display: flex;
      align-items: center;

      .share-btn {
        display: flex;
        justify-content: center;
        margin-right: $gap * 1.5;
      }
    }
  }
}

.share-btn {
  display: flex;
  width: 100%;
  padding: 7px 8px;
  border-radius: 8px;
  padding-right: $gap;
  align-items: center;
  text-decoration: none;
  box-sizing: border-box;
  transition: all .2s ease;
  border: 1px solid #C2BEC8;

  &:hover {
    border: 1px solid #fff;
  }

  &__icon {
    width: 24px;
    height: 24px;
    margin-right: $gap / 2;

    /deep/ path {
      fill: #fff;
    }
  }

  &__text {
    color: #fff;
    font-weight: 600;
  }
}
</style>
