<template>
  <svg
    width="10"
    height="18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.667 1l6.666 7.997L1.667 17"
      :stroke="color"
      stroke-width="2"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#2D2D2D'
    }
  }
}
</script>
