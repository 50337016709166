<template>
  <svg
    fill="none"
    viewBox="0 0 25 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#fff"
      d="M14.054 2.157L6.95 7.354H3.708a.856.856 0 00-.852.852v7.58c0 .466.385.85.852.85h3.299l7.051 5.195c.348.348 1.363.172 1.363-.639V2.84c-.004-.835-.99-1.015-1.367-.683zM6.39 14.975h-1.87V9.012h1.874v5.963h-.004zm7.326 4.555l-5.579-4.096V8.558l5.579-4.101V19.53zM20.738 6.418a.861.861 0 00-1.19-.04.861.861 0 00-.042 1.19c2.3 2.468 2.3 6.43 0 8.857-.299.34-.299.892.041 1.191.287.287.84.307 1.191-.086 2.898-3.065 2.898-8.046 0-11.112z"
    />
    <path
      fill="#fff"
      d="M18.229 9.057a.861.861 0 00-1.191-.04.823.823 0 000 1.19c.937.978.937 2.599 0 3.577-.299.34-.299.892.04 1.191a.832.832 0 001.151-.04c1.576-1.58 1.53-4.261 0-5.878z"
    />
  </svg>
</template>

<script>
export default {}
</script>
